import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import headerStyles from "./header.module.scss"
import Logo from "../../UI/Logo"

const Header = ({ siteTitle, dark }) => {
  const [sticky, setSticky] = useState(false)
  const [mobile, setMobile] = useState(false)

  useEffect(e => {
    handleMobile()
    if (window.scrollY > 100) {
      setSticky(true)
    } else {
      setSticky(false)
    }
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setSticky(true)
      } else {
        setSticky(false)
      }
    })
  })

  const handleMobile = event => {
    if (mobile) {
      document.body.classList.add("overflow")
    } else {
      document.body.classList.remove("overflow")
    }
  }

  return (
    <header
      className={`${headerStyles.header} ${
        sticky ? headerStyles.headerSticky : ""
      } ${mobile ? headerStyles.headerMobile : ""} ${
        dark ? headerStyles.headerDark : ""
      }`}
    >
      <div className={`${headerStyles.wrapper} wrapper`}>
        <div className={headerStyles.header__container}>
          <ul>
            <li>
              <Link
                to="/about"
                className={`${
                  dark
                    ? headerStyles.header__linkDark
                    : headerStyles.header__link
                } header__link--left`}
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/"
                className={`${headerStyles.header__logo} ${
                  dark ? headerStyles.dark : ""
                } `}
              >
                <Logo />
              </Link>
            </li>
            <li>
              <Link
                to="/talk-to-us"
                className={`${
                  dark
                    ? headerStyles.header__linkDark
                    : headerStyles.header__link
                } header__link--right`}
              >
                Talk to us
              </Link>
            </li>
          </ul>

          <div
            onClick={() => setMobile(!mobile)}
            className={headerStyles.hamburger}
          >
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </div>
      <div className={headerStyles.headerMobileWrapper}>
        <ul>
          <li>
            <Link to="/about" className={headerStyles.headerMobileLink}>
              About
            </Link>
          </li>
          <li>
            <Link to="/talk-to-us" className={headerStyles.headerMobileLink}>
              Talk to us
            </Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
