import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import "./styles.scss"

const Cookies = () => {
  const [showCookies, setShowCookies] = useState("false")

  useEffect(() => {
    const cookie = localStorage.getItem("showCookies")
    if (cookie === "true") {
      setShowCookies("false")
    } else {
      setShowCookies("true")
    }
  })

  const handleClose = e => {
    e.preventDefault()
    localStorage.setItem("showCookies", "true")
    setShowCookies(false)
  }

  if (showCookies != "true") {
    return false
  } else {
    return (
      <div className="cookies">
        <div className="wrapper">
          <div className="popup">
            <p className="popup__content">
              This website uses cookies to ensure you get the best experience on
              our website.
            </p>
            <div className="popup__buttons">
              <button onClick={e => handleClose(e)}>Got it</button>
              <Link to="/cookies-policy">Read more</Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Cookies
