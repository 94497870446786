import React from "react"
import PropTypes from "prop-types"

const Title = ({ type, className, children }) => {
  const CustomTag = `${type}`
  return <CustomTag className={className}>{children}</CustomTag>
}

Title.propTypes = {
  className: PropTypes.string,
}

Title.defaultProps = {
  className: ``,
}

Title.propTypes = {
  type: PropTypes.string,
}

Title.defaultProps = {
  type: `h2`,
}
export default Title
