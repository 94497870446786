/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Navigation/Header/header"
import Footer from "./Navigation/Footer/footer"
import Cookies from "./Sections/Cookies/index"
const Layout = ({ children, displayFooter, isDark }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const darkMenuPages = [
    "/about",
    ,
    "/about/",
    "/talk-to-us",
    "/talk-to-us/",
    "/schedule-appointment",
    "/schedule-appointment/",
  ]
  let footer = ""
  if (displayFooter == "false") {
    footer = ""
  } else {
    footer = <Footer />
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} dark={isDark} />
      <main>
        <Cookies />
        {children}
      </main>
      {footer}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
