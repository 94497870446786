import React from "react"
const Logo = () => {
  return (
    <svg width="106px" height="64px" viewBox="0 0 106 64" version="1.1">
      <g
        id="Homepage"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Hompage-1.1-(1)"
          transform="translate(-667.000000, -28.000000)"
          fill="#FFFFFF"
          fillRule="nonzero"
        >
          <g id="Menu">
            <g id="Sistable_Logo" transform="translate(667.000000, 28.000000)">
              <path
                d="M67.4779259,0 L67.4779259,0 C74.3466667,0 79.9146667,5.36177778 79.9146667,11.9757037 L79.9146667,28.6998519 L79.9146667,28.6998519 C73.9531852,28.6998519 69.12,23.8666667 69.12,17.9045926 L69.12,11.5205926 C69.12,8.22696296 66.3466667,5.55674074 62.9262222,5.55674074 L62.9262222,5.55674074 C59.5057778,5.55674074 56.7324444,8.22696296 56.7324444,11.5205926 L56.7324444,17.1152593 C56.7324444,20.9244444 59.9401481,24.013037 63.8962963,24.013037 L65.9857778,24.013037 L65.9857778,24.1677037 C65.9857778,30.2121481 61.0856296,35.1122963 55.0411852,35.1122963 L55.0411852,11.9757037 C55.0411852,5.36177778 60.6091852,0 67.4779259,0 Z"
                id="Path"
              ></path>
              <path
                d="M38.3028148,0 L38.3028148,0 C31.4340741,0 25.8660741,5.36177778 25.8660741,11.9757037 L25.8660741,28.6998519 L25.8660741,28.6998519 C28.7291548,28.6998519 31.4749713,27.5624974 33.4994751,25.5379936 C35.5239789,23.5134898 36.6613333,20.7676734 36.6613333,17.9045926 L36.6613333,11.5205926 C36.6613333,8.22696296 39.4340741,5.55674074 42.8551111,5.55674074 L42.8551111,5.55674074 C46.2755556,5.55674074 49.0482963,8.22696296 49.0482963,11.5205926 L49.0482963,17.1152593 C49.0482963,20.9244444 45.8411852,24.013037 41.885037,24.013037 L39.7955556,24.013037 L39.7955556,24.1677037 C39.7955556,30.2121481 44.6951111,35.1122963 50.7395556,35.1122963 L50.7395556,11.9757037 C50.7395556,5.36177778 45.1715556,0 38.3028148,0 Z"
                id="Path"
              ></path>
              <path
                d="M4.65185185,57.3671111 C4.68207407,59.2041481 4.80296296,60.0764444 5.10459259,60.7922963 C5.61837037,62.0071111 6.64533333,62.6293333 8.12681481,62.6293333 C10.1505185,62.6293333 11.4186667,61.3522963 11.4186667,59.3285926 C11.4186667,58.3004444 11.0868148,57.4595556 10.4521481,56.8681481 C9.81807407,56.2453333 9.39496296,56.0272593 7.46192593,55.2485926 L5.5277037,54.4705185 C3.71555556,53.7546667 2.92977778,53.3185185 2.08414815,52.4776296 C1.00731088,51.420244 0.408110637,49.9695374 0.424888889,48.4604444 C0.424888889,44.6613333 3.41511111,42.2317037 8.12859259,42.2317037 C12.658963,42.2317037 15.4382222,44.6020741 15.408,48.4296296 L10.8740741,48.4296296 C10.8148148,46.5605926 10.6625185,45.7511111 10.2696296,44.9102222 C9.87733333,44.1321481 8.82014815,43.570963 7.73274074,43.570963 C5.95081481,43.570963 4.83318519,44.7561481 4.83318519,46.685037 C4.83318519,48.4296296 5.61837037,49.2391111 8.42725926,50.3591111 L10.24,51.106963 C12.4746667,52.0100741 13.3807407,52.5078519 14.2571852,53.3801481 C15.2841481,54.4082963 15.8571852,55.8405926 15.8571852,57.4595556 C15.8571852,59.2965926 15.1626667,61.0103704 13.9241481,62.1931852 C12.7460741,63.3143704 10.5108148,63.9994074 7.91348148,63.9994074 C5.85955556,63.9994074 4.1677037,63.6260741 2.98962963,62.9404444 C1.14785185,61.8826667 0,59.7961481 0,57.5537778 L0,57.3671111 L4.65185185,57.3671111 Z"
                id="Path"
              ></path>
              <path
                d="M23.5662222,44.3188148 C23.5662222,45.7825185 22.5694815,46.685037 20.9386667,46.685037 C19.2794074,46.685037 18.2198519,45.7511111 18.2198519,44.2554074 C18.2198519,42.7597037 19.277037,41.794963 20.9386667,41.794963 C22.5694815,41.7961481 23.5662222,42.7306667 23.5662222,44.3188148 Z M23.1437037,63.5016296 L18.6127407,63.5016296 L18.6127407,49.7368889 L23.1437037,49.7368889 L23.1437037,63.5016296 Z"
                id="Shape"
              ></path>
              <path
                d="M29.2296296,59.4536296 C29.2296296,60.512 29.3481481,61.0725926 29.5917037,61.6023704 C29.9241481,62.2542222 30.7697778,62.6921481 31.7665185,62.6921481 C33.1259259,62.6921481 34.032,61.8826667 34.032,60.6678519 C34.032,59.6088889 33.3979259,59.0488889 31.7060741,58.5813333 L30.0752593,58.1457778 C28.4438519,57.6782222 27.8097778,57.4293333 27.1152593,56.8065185 C26.269037,56.0906667 25.8162963,55.0625185 25.8162963,53.8791111 C25.8162963,51.1383704 28.112,49.3321481 31.6154074,49.3321481 C34.9985185,49.3321481 37.1128889,50.8894815 37.3244444,53.5365926 L33.9413333,53.5365926 C33.8808889,51.4500741 33.1851852,50.5481481 31.6154074,50.5481481 C30.3472593,50.5481481 29.4714074,51.3268148 29.4714074,52.3851852 C29.4714074,53.3505185 30.1961481,53.9733333 31.7665185,54.4094815 L33.5792593,54.9072593 C36.3881481,55.6859259 37.6568889,57.0251852 37.6568889,59.2047407 C37.6568889,62.1321481 35.4216296,63.9075556 31.6764444,63.9075556 C27.9004444,63.9075556 25.6954074,62.288 25.634963,59.4542222 L29.2296296,59.4536296 Z"
                id="Path"
              ></path>
              <path
                d="M40.4414815,49.7368889 L40.4414815,46.0936296 L44.9724444,45.0032593 L44.9724444,49.7368889 L48.626963,49.7368889 L48.626963,51.1075556 L44.9724444,51.1075556 L44.9724444,59.3908148 C44.9724444,61.570963 45.394963,62.3182222 46.6032593,62.3182222 C47.0868148,62.3182222 47.7208889,62.1937778 48.626963,61.9140741 L48.626963,63.2835556 C47.2681481,63.6574815 46.0900741,63.8441481 44.8213333,63.8441481 C42.9487407,63.8441481 41.6805926,63.2213333 40.9848889,61.9757037 C40.5925926,61.1976296 40.4414815,60.418963 40.4414815,58.8622222 L40.4414815,51.1075556 L38.448,51.1075556 L38.448,49.7368889 L40.4414815,49.7368889 Z"
                id="Path"
              ></path>
              <path
                d="M59.3380741,60.6364444 C58.5522963,62.941037 57.1934815,63.906963 54.7158519,63.906963 C51.7256296,63.906963 49.9431111,62.381037 49.9431111,59.826963 C49.9431111,58.0835556 50.7288889,56.9001481 52.2992593,56.1214815 C53.4471111,55.5608889 55.4103704,55.28 59.0654815,55.186963 L59.0654815,53.6296296 C59.0654815,51.3878519 58.5214815,50.5155556 57.072,50.5155556 C55.6823704,50.5155556 55.0785185,51.3878519 55.0174815,53.4121481 L55.0174815,53.661037 L50.6382222,53.661037 C50.6684444,52.4758519 51.1211852,51.4808889 51.9668148,50.7028148 C52.9937778,49.7997037 54.7158519,49.3321481 57.1324444,49.3321481 C59.7297778,49.3321481 61.5419259,49.8927407 62.5392593,51.0139259 C63.2942222,51.8548148 63.5964444,52.9137778 63.5964444,54.9380741 L63.5964444,63.5016296 L59.3374815,63.5016296 L59.3380741,60.6364444 Z M54.5048889,59.5158519 C54.5048889,61.2906667 55.1697778,62.256 56.3774815,62.256 C57.4346667,62.256 58.2802963,61.5395556 58.7336296,60.2322963 C58.9451852,59.6088889 59.0660741,58.1765926 59.0660741,56.2459259 C55.7131852,56.2459259 54.5048889,57.1176296 54.5048889,59.5158519 Z"
                id="Shape"
              ></path>
              <path
                d="M71.3321481,41.485037 L71.3321481,52.2287407 C72.1475556,50.2666667 73.6278519,49.3321481 75.8358519,49.3321481 C77.5271111,49.3321481 79.0951111,49.984 80.1552593,51.1691852 C81.1217778,52.2281481 81.7256296,54.2832593 81.7256296,56.5256296 C81.7256296,59.1099259 81.0008889,61.2586667 79.7623704,62.3804444 C78.7051852,63.3457778 77.3457778,63.8435556 75.8358519,63.8435556 C73.5401481,63.8435556 71.938963,62.7845926 71.2136296,60.7294815 L71.2136296,63.501037 L66.8035556,63.501037 L66.8035556,41.485037 L71.3321481,41.485037 Z M71.3019259,56.5576296 C71.3019259,60.1386667 72.48,62.256 74.4734815,62.256 C76.1327407,62.256 76.9807407,60.512 76.9807407,57.0868148 C76.9807407,52.6334815 76.2554074,50.8580741 74.4432593,50.8580741 C72.48,50.8580741 71.3019259,53.0074074 71.3019259,56.5576296 Z"
                id="Shape"
              ></path>
              <polygon
                id="Path"
                points="88.9025185 63.5016296 84.3715556 63.5016296 84.3715556 41.485037 88.9025185 41.485037"
              ></polygon>
              <path
                d="M96.0545185,56.8373333 C96.0847407,60.9173333 97.1117037,62.5985185 99.5585185,62.5985185 C101.492148,62.5985185 103.032296,61.4133333 103.66637,59.4222222 L105.418667,59.4222222 C105.116444,60.512 104.814222,61.2 104.21037,61.8518519 C103.062519,63.1602963 101.099259,63.9075556 98.8337778,63.9075556 C94.2115556,63.9075556 91.4334815,61.1668148 91.4334815,56.589037 C91.4334815,52.0420741 94.1214815,49.3327407 98.6524444,49.3327407 C101.431111,49.3327407 103.66637,50.4231111 104.693333,52.2607407 C105.448889,53.568 105.750519,54.7828148 105.780741,56.8379259 L96.0545185,56.8373333 Z M101.159704,55.6521481 C101.129481,52.2577778 100.283259,50.5445926 98.6524444,50.5445926 C96.9931852,50.5445926 96.114963,52.2263704 96.0545185,55.6521481 L101.159704,55.6521481 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Logo
