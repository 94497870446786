import React from "react"
import { Link, graphql } from "gatsby"

import Title from "../../UI/Title.js"

import footerStyles from "./footer.module.scss"

import logo from "../../../assets/images/sistable-logo__footer.svg"
import instagram from "../../../assets/images/instagram.svg"

const Footer = () => (
  <footer className={footerStyles.footer}>
    <div className="wrapper">
      <div className={footerStyles.footer__top}>
        <Link to="/" className={footerStyles.footer__logo}>
          <img src={logo} />
        </Link>
        <p className={footerStyles.footer__paragraph}>
          Online life coaching for women. Get coached by a sister who
          understands you!
        </p>
        <ul className={footerStyles.footer__links}>
          <li>
            <Link to="/about" className={footerStyles.footer__link}>
              About
            </Link>
          </li>
          <li>
            <Link to="/talk-to-us" className={footerStyles.footer__link}>
              Talk to us
            </Link>
          </li>
        </ul>
        <div className={footerStyles.footer__contact}>
          <a href="mailto:hello@sistable.com">hello@sistable.com</a>
          <a target="_blank" href="https://www.instagram.com/sistable.coach/">
            <img src={instagram} />
          </a>
        </div>
      </div>
      <div className={footerStyles.footer__middle}>
        <span>Sistable Oü</span>
        <span>Sepapaja tn 6, 15551 Tallinn, Estonia</span>
        <span>VAT ID EE102260586</span>
      </div>
      <div className={footerStyles.footer__bottom}>
        <p className={footerStyles.footer__copyright}>
          Copyright © 2020 Sistable – All rights reserved. Designed and built by{" "}
          <strong>
            <a target="_blank" href="https://adchitects.co/websites">
              Adchitects
            </a>
          </strong>{" "}
          in time and budget.
        </p>
        <ul className={footerStyles.footer__copyright}>
          <li>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Use</Link>
          </li>
          <li>
            <Link to="/cookies-policy">Cookies Policy</Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
)

export default Footer
